<template>
  <div>
    <h4>ORTODONCIA</h4>
    <div class="btn-group btn-block">
      <router-link 
        class="btn btn-light btn-sm" 
        :class="{'active': (baseRoute + l.to) == $route.path}"
        v-for="l in $options.links" :key="l.name" :to="baseRoute + l.to">
        {{l.name}}
      </router-link>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  links: [
    {name: 'Proforma', to: 'proform'},
    {name: 'Ficha de Control', to: ''},
    {name: 'Plan de Tratamiento', to: 'plan'},
    {name: 'Evolucion', to: 'program-quotes'},
    {name: 'Anamnesis', to: 'anamnesis'},
  ],
  props: {
    client_id: {},
    treatment_plan_id: {}
  },
  // data: () => ({
  //   baseRoute: '/dental-clinic/patients/' + this.client_id + '/orthodontics/'
  // }),
  computed: {
    baseRoute() {
      // return '/dental-clinic/patients/' + this.client_id + '/orthodontics/';
      return `/dental-clinic/patients/${this.client_id}/orthodontics/${this.treatment_plan_id}/`
    }
  }
  // dental-clinic/patients/3037/orthodontics'
}
</script>

<style>

</style>
